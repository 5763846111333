@import "defs";

//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@100;300;400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  background: $background_color_default;
  color: $text_color_default;
  font-family: $font_family_default;
}

html,
body,
#__next {
  height: 100%;
  z-index: -1;
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: $text_color_default;
  text-decoration: none;
}

img {
  user-select: none;
  width: 100%;
  height: auto;
}

:focus {
  outline: none;
}

button {
  color: $text_color_default;
  font-family: $font_family_default;
}

input {
  color: $text_color_default;
}

//header

h2 {
  font-family: $font_family_default;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 48px;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
}

h4 {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  margin: 0;
}

p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  color: #e9e9e9;
  margin: 0;
}

@import "src/styles/defs";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f5;

  @media screen and (max-width: 870px) {
    height: 100%;
  }
}

.header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  z-index: 5;
  width: 100%;
  flex-direction: column;

  @include lg {
    width: 1140px;
  }

  @include md {
    flex-direction: row;
  }

}

.logo {
  width: 140px;
  height: 80px;
  margin: 20px;

  &::before {
    content: '';
    width: 140px;
    height: 80px;
    background: url("../../images/logo.png") center;
    background-size: cover;
    position: absolute;
  }

  img {
    width: 100%;
  }
}

.headerMenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @include md {
    justify-content: space-between;
  }
  margin: 20px;
}

.headerInfo {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footerWrapper {
  height: 276px;
  width: 100%;
  display: flex;
  justify-content: center;

  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../../images/footer.png") center;
    background-size: 100%;
    background-blend-mode: multiply;
    z-index: -1;
  }

}

.footer {
  width: 70%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 700px) {
    width: 90%;
    margin: 0;
  }

  .text {
    display: block;
    position: relative;
    padding-bottom: 12px;
    color: #fff;
    font-weight: 600;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 60px;
      height: 3px;
      background-color: #d9272d;
    }
  }

  .company {
    color: #ffffff;
  }
}

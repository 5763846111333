@import "src/styles/defs";

.headerInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 190px;
  margin: 10px;
  font-weight: bolder;

  a {
    color: #676767;
    display: block;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    font-weight: bold;
  }
}

.icon {
  margin-right: 10px;
}

.title {
  color: $text_color_accent;
}